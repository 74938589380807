// Simplex 4.1.3
// Bootswatch

// Color system
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e6e6e6 !default;
$gray-300: #ccc !default;
$gray-400: #bbb !default;
$gray-500: #adb5bd !default;
$gray-600: #777 !default;
$gray-700: #444 !default;
$gray-800: #373a3c !default;
$gray-900: #212529 !default;
$black:    #1b1b1b !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #9B479F !default;
$pink:    #e83e8c !default;
$red:     #D9230F !default;
$orange:  #F98E05 !default;
$yellow:  #ffc107 !default;
$green:   #469408 !default;
$teal:    #20c997 !default;
$cyan:    #029ACF !default;
$amazon-yellow: #f99500 !default;
$amazon-blue: #222e3d !default;
$quintrex-light: #53c1b6;
$quintrex-dark: #222e3d;

$primary:       #53c1b6 !default;
$secondary:     $yellow !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $purple !default;
$danger:        $orange !default;
$light:         $gray-200 !default;
$dark:          $gray-800 !default;

// Body
$body-bg: #f7fbfa !default;

$font-stack: 'Trex-Regular', sans-serif;
$regular: 'Trex-Regular', sans-serif;
$medium: 'Trex-Medium', sans-serif;
$bold: 'Trex-Bold', sans-serif;

$font-family-sans-serif: $font-stack;
$font-size-base: 0.9rem !default;

// Dropdowns
$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $primary !default;


// Navs
$nav-link-padding-y:                .9rem !default;
$nav-link-disabled-color:           $gray-400 !default;
$nav-tabs-border-color:             darken(#fff, 6.5%) !default;


// Navbar
$navbar-dark-color:                 rgba($white,.75) !default;
$navbar-dark-hover-color:           $white !default;


// Pagination
$pagination-border-color:           $nav-tabs-border-color !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $primary !default;
$pagination-hover-border-color:     $primary !default;

$pagination-disabled-color:         $gray-400 !default;
$pagination-disabled-border-color:  $pagination-border-color !default;


// Jumbotron
$jumbotron-bg:                      $nav-tabs-border-color !default;


// Cards
$card-border-color:                 $nav-tabs-border-color !default;


// Popovers
$popover-border-color:              $nav-tabs-border-color !default;


// Modals
$modal-content-border-color:        $nav-tabs-border-color !default;
$modal-header-border-color:         $nav-tabs-border-color !default;


// Progress bars
$progress-bar-color:                $primary !default;


// List group
$list-group-border-color:           $nav-tabs-border-color !default;
$list-group-disabled-bg:            $nav-tabs-border-color !default;


// Breadcrumbs
$breadcrumb-bg:                     $white !default;
