@import "variables";
@import "font";
@import "bootstrap/bootstrap";
@import "bootstrap_customization";
@import "typography";
@import "mixins";
@import "icons";

$navbar-top-height: 85px;
$facebook-blue: #3b5898;
$youtube-red: #D9230F;
$youchoose-magenta: #e33180;

$tiktok-blue: #69c9d0;
$tiktok-red: #ee1d52;
$tiktok-black: #010101;

body {
    padding-top: $navbar-top-height;

    &.default {
        a {
            color: $primary;
        }
        .navbar-top {
            @include trex-navbar(#1b1b1b, $white, $white, $white);
        }
        .jumbotron-home {
            @include trex-jumbotron(#1b1b1b, $white, true)
        }
        .navbar-nav {
          background-color: $black;
        }
        .nav-link {
          font-size: 1.1rem;
          margin-left: 0.4em;
          margin-right: 0.4em;
        }

        .navbar-toggler-icon {
          background: transparent url($icon-navbar-toggler-white) no-repeat;
          background-size: contain;
        }

        pre b, .bold, strong, .strong {
          font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
          font-weight: 900;
        }

        .navbar-nav{
          padding: 0 !important;  
        }
    }

    &.tiktok {

      .highlight {
        background-color: $tiktok-blue
      }
      .highlight.secondary {
        background-color: $tiktok-red !important;
        color: $white;
      }
      a {
        color: $tiktok-red;
      }
      a:hover {
        color: $tiktok-blue;
      }

      .nav-item {
        padding-right: 25px;
      }

      .nav-link {
        color: white;
        font-size: 1.2rem;
      }
      .nav-link:hover {
        color: white;
      }

      .navbar-top {
        background: linear-gradient(to right, $white, $tiktok-red);
      }

      .jumbotron-home {
        background-color: $tiktok-red;
        color: $white;
      }

      .jumbotron {
        background-color: $tiktok-red;
        color: $white;
        margin-bottom: 0;
        border-radius: 0;
      }

      .jumbotron-full {
        @include trex-jumbotron($tiktok-blue, $white, false);
      }

      .navbar-nav {
        background-color: $tiktok-red;
        padding: 14px 50px;
      }

      .nav-pills {
        .nav-link {
          &.active {
            background-color: $tiktok-red;
          }
        }
      }

      footer {
        border-color: $tiktok-blue;
      }

      .navbar-toggler-icon {
        background: transparent url($icon-navbar-toggler-white) no-repeat;
        background-size: contain;
      }

      ul, ol {
        font-size: 1.4rem;
      }

    }


    &.facebook {

        .btn-facebook {
            background-color: $facebook-blue;
            color: $white;
        }
       .highlight {
          background-color: $facebook-blue;
        }
        .highlight.secondary {
          background-color: $primary !important;
          color: $white;
        }
        a {
            color: $facebook-blue;
        }

        .navbar-top {
            @include trex-navbar($facebook-blue, $white, $white, $white);
        }

        .jumbotron-home {
            @include trex-jumbotron($facebook-blue, $white, false);
        }

        .jumbotron-full {
            @include trex-jumbotron($facebook-blue, $white, false);
        }

        .navbar-nav {
          background-color: $facebook-blue;
        }

        .nav-pills {
            .nav-link {
                &.active {
                    background-color: $facebook-blue;
                }
            }
        }

        footer {
          border-color: $facebook-blue;
        }

        .navbar-toggler-icon {
          background: transparent url($icon-navbar-toggler-white) no-repeat;
          background-size: contain;
        }
    }

    &.youtube {

        a {
            color: $youtube-red;
        }
        .highlight {
          background-color: $youtube-red;
          color: $white;
          font-family: $medium;
        }
        .highlight.secondary {
          background-color: whitesmoke !important;
          color: $black;
        }
        .navbar-top {
            @include trex-navbar($white, $black, $red, $black);
        }

        .jumbotron-home {
            @include trex-jumbotron($white, $black, false);
        }

        .navbar-nav {
          background-color: $white;
        }

        footer {
          border-color: $youtube-red;
        }
    }

    &.pornhub {
        a {
          color: $orange;
        }
        .highlight.secondary {
          background-color: $black !important;
          color: $orange;
        }
        .highlight {
          background-color: $orange;
        }
        footer {
          border-color: $orange;
          border-width: 3px;
          border-image: linear-gradient(45deg, rgb(249,142,5), rgb(250,224,66)) 1;
          background-color: #1b1b1b;
          margin-top: 1rem;
          padding: 1.5rem 0;
        }
        #colophon .main-navigation a{
          color: #f7fbfa !important;
          font-weight: 600;
          letter-spacing: 0.03rem;
        }
        #colophon .main-navigation a :hover{
          text-decoration: underline !important;
          color: $orange !important;
          font-weight: 600;
          letter-spacing: 0.03rem;
        }
        #colophon .logo a {
          background-image: url('/images/trex-logow.svg');
          background-size: 60px;
        }
        .navbar-nav {
          background-color: $black;
        }
        a.nav-link{
          font-weight: 600;
          letter-spacing: 0.03rem;
        }
        a.nav-link:hover{
          text-decoration: underline !important;
          color: $orange !important;
        }
        .navbar-expand-md .navbar-nav .nav-link {
          padding-right: 1rem;
          padding-left: 1rem; 
        }
        .dropdown-item:hover{
          background-color: $orange;
        }
        .dropdown-menu {
          background-color: #1b1b1b;
          width: 40%;
        }
        .jumbotron-home {
          background-color: $black;
          color: $orange;
        }
        .navbar-toggler-icon {
          background: transparent url($icon-navbar-toggler-white) no-repeat;
          background-size: contain;
        }
        .navbar-top {
          @include trex-navbar($black, $white, $black, $black);
        }
        @media (min-width: 768px) {
          .jumbotron {
            padding: 2rem 2rem 2rem 2rem;
            margin-bottom: 3rem;
            }
          }
          .jumbotron-home {
            min-height: 340px;
            background-color: none;
            color: $orange;
          }
          .jumbotron-home small{
            color: #1b1b1b;
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 10px;
          }

          /* this class is invoked in homeph which is a specially crafted homepage */
          div.featured-ph-box {
            position: relative;
            display: inline-block;
            top: -10rem;
            padding: 1.1rem 1rem 0.8rem 1.1rem;
            margin: 0 10px 0.5rem 10px;
            box-shadow: none;
            border-style: solid;
            border-width: 3px;
            border-radius: 0;
            border-image: linear-gradient(45deg, rgb(249,142,5), rgb(250,224,66)) 1;
            background-color: #f7fbfa;
          }

          div.featured-ph-box a.image {
            height: 13rem;
            margin-bottom: 1rem;
            display: block;
            text-indent: -99999px;
            background-color: #d9d9d9;
            width: 100%;
            text-align: center;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          div.featured-ph-box a.text {
            text-decoration: none;
            font-size: 21px;
            font-family: "Trex-Bold";

            &:hover {
              text-decoration: underline;
            }
          } 

          div.featured-ph-box span{
            font-family: monospace;
            font-weight: 900;
            line-height: 1.8em;
          }

          div.featured-ph-box p{
            font-size: 1.2em;
            letter-spacing: 0.02em;
            font-weight: 900;
            line-height: 1.4em;
          }

          .main-div {
            padding-bottom: 2rem;
            margin-top: -7rem;
          }
    }



    &.amazon {
        a {
          color: $amazon-yellow;
        }
        .highlight.secondary {
          background-color: $amazon-blue !important;
          color: $amazon-yellow;
        }
        .highlight {
          background-color: $amazon-yellow;
        }
        footer {
          border-color: $amazon-yellow;
        }
        .navbar-nav {
          background-color: $amazon-blue;
        }
        .jumbotron-home {
          background-color: $amazon-blue;
          color: $amazon-yellow;
        }
        .navbar-top {
          @include trex-navbar($amazon-blue, $white, $amazon-yellow, $amazon-blue);
        }
        .navbar-toggler-icon {
          background: transparent url($icon-navbar-toggler-white) no-repeat;
          background-size: contain;
        }
        .dropdown-menu {
          background-color: $amazon-blue;
        }
    }

    &.quintrex {
        a {
          color: $quintrex-light;
        }
        .highlight.secondary {
          background-color: $amazon-blue !important;
          color: $quintrex-light;
        }
        .highlight {
          background-color: $quintrex-light;
        }
        footer {
          border-color: $amazon-blue;
        }
        .jumbotron-home {
          background-color: $quintrex-dark;
          color: $quintrex-light;
        }
        .navbar-top {
          @include trex-navbar($amazon-blue, $white, $quintrex-light, $amazon-blue);
        }
        .navbar-toggler-icon {
          background: transparent url($icon-navbar-toggler-white) no-repeat;
          background-size: contain;
        }
        .dropdown-menu {
          background-color: $quintrex-dark;
        }
    }
    /* this was included above 

    &.quintrex {
        a {
            color: $primary;
        }
        .navbar-top {
            @include trex-navbar(#222e3d, $white, $white, $white);
        }
        .jumbotron-home {
            @include trex-jumbotron(#222e3d, $white, true)
        }
        .navbar-nav {
          background-color: $amazon-blue;
        }
        .navbar-toggler-icon {
          background: transparent url($icon-navbar-toggler-white) no-repeat;
          background-size: contain;
        }
    } */

    &.youchoose {
      a {
        color: $youchoose-magenta;
      }
      .highlight.secondary {
        background-color: $white !important;
        color: #FED766;
      }
      .highlight {
        background-color: $youchoose-magenta;
      }
      footer {
        border-color: $youchoose-magenta;
        background-color: #000;
      }
      @media (min-width: 768px) {
      .jumbotron {
        padding: 4.5rem 2rem 0rem 3.5rem;
        margin-bottom: 0rem;
        }
      }
      .jumbotron-home {
        min-height: 480px;
        background-color: #ffffff;
        color: $youchoose-magenta;
      }
      .jumbotron-home small{
        color: #1b1b1b;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .navbar-top {
        @include trex-navbar($white, $youchoose-magenta, $youchoose-magenta, $youchoose-magenta);
      }
      .navbar-toggler-icon {
        background: transparent url($icon-navbar-toggler-white) no-repeat;
        background-size: contain;
      }
      .dropdown-menu {
        background-color: $white; 
      }
      .dropdown-item:hover{
        background-color: $youchoose-magenta;
      }
      #colophon small, #colophon a {
        color: #fff !important;
      }
      #colophon a {
        font-size: large;
      }
      #colophon .logo a {
        background-image: url('/images/trex-logow.svg');
        background-size: 60px;
      }
      .navbar .navbar-brand img {
        height: 35px;
      }

      div.featured-box{
        margin: 0 35px 2.8rem 15px;
        box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
      }
      h3 {
        line-height: 140%;
        font-size: 25px;
        font-family: $regular !important;
        text-align: left;
      }
      @media only screen and ( min-width: 600px ) {
        h3 { 
          text-align: justify; 
        }
      }
      
      h3 b, .bold, strong, .strong{
        color: #e33180;
        font-weight: 200 !important;
      }
  }
}

/* Main Navbar */
.navbar {
    border: 0;
    box-shadow: 0 0 0;
    height: 85px;

    button.navbar-toggler {
        border-radius: 2px;
        background-color: transparent;

        span.navbar-toggler-icon {
           color: $black;
        }
    }

    .navbar-brand {
        font-family: 'Trex-Bold';

        img {
            height: 45px;
            display: inline-block;
        }
    }
}

div.jumbotron-home {
    border-radius: 0;
    min-height: 500px;

    h1 {
        width: 70%;
        font-weight: bold;
    }

    small {
        text-transform: uppercase;
        color: #e9e9e9;
    }
}

/* Home - Featured Boxes */
div.featured-box {
    position: relative;
    top: -10rem;
    padding: 2rem;
    margin: 0 15px 2rem 15px;
    background-color: $white;
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 13px;

    a.image {
        display: block;
        text-indent: -99999px;
        background-color: #d9d9d9;
        width: 100%;
        text-align: center;
        height: 15rem;
        margin-bottom: 1.5rem;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    a.text {
        text-decoration: none;
        font-size: 21px;
        font-family: "Trex-Bold";
        margin-bottom: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}

.home-content {
  h2 {
    word-break: break-all;
  }
}
.dropdown-menu {
  right: 0;
  line-height: 30px;
}

.navbar-nav {
  padding: 10px;
}

.navbar-toggler-icon {
  background: transparent url($icon-navbar-toggler) no-repeat;
  background-size: contain;
}

.icon-big {
  display: inline-block;
  margin: 10px 0;
  background-size: contain !important;
  width: 80px;
  height: 80px;
}

.firefox-icon {
  background: transparent url($icon-firefox) no-repeat;

}
.chrome-icon {
  background: transparent url($icon-chrome) no-repeat;
}

#colophon p {
  text-align: right;
  margin-bottom: 20px;
}

/*
#colophon .logo a {
  width: 60px;
  height: 60px;
  display: block;
  text-indent: -99999px;
  margin: 0 10px 0 0;
  float: left;
  padding: 5px 0 0 0;
  background: transparent url($icon-colophon-logo) no-repeat;
  background-size: 65px;
  background-position-y: center;
} */

#colophon small {
  display: inline-block;
}


#colophon .main-navigation a {
  padding: 0;
  margin: 5px 0;
  line-height: 30px;
  color: $black !important;
  display: inline-block;
  text-decoration: none;
}


.main-navigation ul {
  margin: 0;
  padding: 0;
}
.main-navigation li {
  list-style: none;
  display: inline-block;
  margin: 5px 15px 5px;
  text-align: left;
}

footer {
    margin-top: 4.5rem;
    padding: 2.5rem 0;
    color: $black;
    background-color: $gray-200;
    border-top: 40px solid $primary;

    a {
        color: $primary !important;
    }
}

ul.list li {
  list-style: none;
  margin-left: 0;
}

.notfound-icon {
  width: 100%;
  height: auto;
  min-height: 200px;
  max-width: 320px;
  background: transparent url($icon-notfound) no-repeat;
  text-indent: -99999px;
}

.icon-info-circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: 14px;
  background-image: url($icon-info-circle);
  background-repeat: no-repeat;
  opacity: 25%;
  filter: alpha(opacity=25);
}

.icon-no-data {
  width: 48px;
  height: 48px;
  background: transparent url($icon-no-data) no-repeat;
  background-size: contain;
}

.resource {
  background-color: $white;
  margin: 30px 0;
  border: solid 1px $gray-200;
}

.resource-header {
  padding: 15px 20px 10px 20px;
  background-color: $gray-100;
  border-bottom: none;
}

.resource-body {
  padding: 0 20px 10px 20px;
  word-break: normal;
}

.resource-header .badge {
  font-size: 13px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.resource-header a {
  color: $black !important;
  text-decoration: underline;
}

.resource .kind {
  display: inline-block;
  float: left;
  height: 27px;
  width: 35px;

}

.resource .kind.paper,
.resource .kind.publication,
.resource .kind.article {
  background: transparent url($icon-publication) no-repeat;
}

.resource .kind.event {
  background: transparent url($icon-event) no-repeat;
}

.resource .kind.link {
  background: transparent url($icon-link) no-repeat;
}

.resource .kind.video {
  background: transparent url($icon-video) no-repeat;
}

/*personal page*/
.icon-small {
  display: inline-block;
  padding-left: 20px;
  min-height: 15px;
  margin-right: 10px;
  color: $black !important;
}

.compare {
  background: transparent url($icon-compare) no-repeat 0 3px;
}

.arrow-down {
  background: transparent url($icon-arrow-down) no-repeat 0 8px;
}

.arrow-up {
  background: transparent url($icon-arrow-up) no-repeat 0 8px;
}

.delete {
  background: transparent url($icon-delete) no-repeat 0 3px;
}
.related {
  background: transparent url($icon-related) no-repeat 0 3px;
}
.author {
  background: transparent url($icon-author) no-repeat 0 3px;
}

.featured-alert {
  border-radius: 2px;
  border: 1px solid darkgray;
  padding: 1px;
  background-color: white;
  font-weight: 600;
}

@import "components";
@import "facebook";
@import "query";
