/* Regular */
@font-face {
  font-family: 'Trex-Regular';
  src: url('../../fonts/Trex-Regular.eot');
  src: url('../../fonts/Trex-Regular.eot#iefix') format('embedded-opentype'),
  url('../../fonts/Trex-Regular.woff') format('woff'),
  url('../../fonts/Trex-Regular.ttf') format('truetype'),
  url('../../fonts/Trex-Regular.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}


/* Bold */
@font-face {
  font-family: 'Trex-Medium';
  src: url('../../fonts/Trex-Medium.eot');
  src: url('../../fonts/Trex-Medium.eot#iefix') format('embedded-opentype'),
  url('../../fonts/Trex-Medium.woff') format('woff'),
  url('../../fonts/Trex-Medium.ttf') format('truetype'),
  url('../../fonts/Trex-Medium.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}


/* Bold */
@font-face {
  font-family: 'Trex-Bold';
  src: url('../../fonts/Trex-Bold.eot');
  src: url('../../fonts/Trex-Bold.eot#iefix') format('embedded-opentype'),
  url('../../fonts/Trex-Bold.woff') format('woff'),
  url('../../fonts/Trex-Bold.ttf') format('truetype'),
  url('../../fonts/Trex-Bold.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

