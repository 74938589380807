/*  _facebook */

#reality-check {

  $facebook-border-color: #d9d9d9;
  $facebook-border-radius: 3px;

  background-color: #e9ebee;
  background-size: 850px 350px;
  min-height: 575px;
  margin-bottom: -4.5rem;

  .bg-facebook { 
    background-color: #f7fbfa;
  }

  div.container-fb {
    padding: 0;
    margin: 0;
    min-height: 100px;
    background-color: $white;
    color: $black;
    border: 1px solid $facebook-border-color;
    border-radius: $facebook-border-radius;
  }

  div.masthead {
    padding: 0;
    margin: 0;
    border-radius: $facebook-border-radius;
    background-color: $white;
    border: 1px solid $facebook-border-color;

    div.logo {
      width: 115px;
      height: 115px;
      display: inline-block;
      background-color: $white;
      background-image: url('/images/fb-reality-check.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 10% center;
      overflow: hidden;
    }
 
    h1 {
      display: inline-block;
      color: $gray-900;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: .5rem;
    }  
  }

  #loading-data {
    img {
      height: 48px;
      opacity: 0.20;
      filter: grayscale(100%);
    }
  }

  div.profile {
    &:empty {
        display: block;
        width: 400px;
        content: '\00a0';
        background: $gray-300;
    }

    div.avatar {
      background: $white;
      border: 1px solid $facebook-border-color;
      border-radius: $facebook-border-radius;
      width: 72px;
      height: 72px;

      img {
        width: 100%;
        height: 100%;
        opacity: 0.35;
      } 
    }

    h1 {
      text-transform: capitalize; 
    }
  }

  #visualization {
    position: relative;
    top: -65px;
  }

  ul.nav-tabs {
    a.nav-link {
      &.active {
        background: $white;
      }
    }

    li.nav-item {
      margin-bottom: -2px;
      line-height: 1rem;

      &.show {
        a.nav-link {
          background: $white;
          border-color: $white;
        }
      }
    }
  }

  div.tab-content {
    background: $white;
    border: 1px solid $facebook-border-color;
    border-radius-bottom-right: $facebook-border-radius;

    button.btn-overview-paginate {
        width: auto;
        height: 100%;
        border: 0px;
        padding: 0 .7rem;
        background: $facebook-blue;
        border-radius: $facebook-border-radius;
        cursor: pointer;
        font-size: 1.25rem;
        font-weight: 100;
        color: $white;

        &:hover {
            background: darken($facebook-blue, 10%);
            color: $white;
        }
    }

    button.btn-overview-inactive {
        width: auto;
        height: 100%;
        border: 0px;
        padding: 0 .7rem;
        background: $gray-200;
        border-radius: $facebook-border-radius;
        cursor: not-allowed;
        font-size: 1.25rem;
        font-weight: 100;
        color: $gray-400;
    }
  }

  div.graph-day {
    &.graph-day-border {
        border-left: 1px solid $facebook-border-color;
    }
    span.seconds {
        font-size: 48px;
        line-height: 48px;
    }
    span.posts {
        font-size: 30px;
        line-height: 30px;
    }
    div.daily-pie {
        max-height: 150px;
    }
    .c3-chart-arc path {
        stroke: $facebook-border-color;
    }
  }

  div.selected-day {
      background-color: #e7f9ff;
      border-radius: 3px;
  }

  div.daily-topic-count {
      font-size: 36px;
  }

  div.feed-item,
  div.stats-item {
    background-color: $white;
    color: #1c1e21;
    border: 1px solid $facebook-border-color;
    border-radius: $facebook-border-radius;
  }

  a.nav-link {
    cursor: pointer;

    &:hover {
        color: $gray-600;
    }
  }

  #daily-timeline {
    margin: 0 1rem;

    li.table-item {
      border-bottom: 1px solid $facebook-border-color;
      padding: 1rem 0;
      width: 100%;      

      span.topic-count {
        padding: 4px 8px;
        margin-right: 10px;
        font-size: 11px;
        border-radius: 24px;
        text-align: center;

        &.num-1 { background-color: $gray-200; }
        &.num-2 { background-color: lighten($yellow, 25%); }
        &.num-3 { background-color: $yellow; }
        &.num-4 { background-color: $orange; }
        &.num-5 { background-color: $red; color: $white; }
        &.num-6 { background-color: $red; color: $white; }
        &.num-7 { background-color: darken($red, 10%); color: $white; }
        &.num-8 { background-color: darken($red, 15%); color: $white; }
        &.num-9 { background-color: darken($red, 20%); color: $white; }
        &.num-10 { background-color: #4e1919; color: $white; }
        &.num-11 { background-color: darken(#4e1919, 5%); color: $white; }
        &.num-12 { background-color: $black; color: $white; }
      }
    }
  }
}

