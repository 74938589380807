
/*--------------------------------------------------------------
## Heading
--------------------------------------------------------------*/
h1, .h1_style {
  font-family: $medium;
}

h2, .h2_style {
  font-family: $bold;
}

h3, .h3_style{
  font-family: $bold;
}

h4, .h4_style {
  font-family: $bold;
}

h5, .h5_style,
h6, .h6_style {
  font-family: $bold;
}

/*--------------------------------------------------------------
## Characters formatting
--------------------------------------------------------------*/
a {
  text-decoration: none;
}

header a, footer a {
  text-decoration: none;
}
div, form, p, .paragraph, small, .small, mark, label, input {
  font-family: $regular;
}
b, .bold, strong, .strong {
  font-family: $bold !important;
}
em, i, .italic {
  font-style: italic;
}
ul,ol {
  font-size: 16px;
}
p, .paragraph {
  font-size: 16px;
  line-height: 160%;
  display: block;
  font-family: $regular;
  margin-bottom: 25px;
}
small, .small {
  font-size: 120%;
  line-height: 150%;
  display: inline-block;
}
kbd, code {
  display: inline-block;
  word-wrap: break-word;
  font-size: 75%;
  padding: 0 2px;
  color: $black;
  background: $gray-100;
  border: solid 1px $gray-200;
}
code.break {
  display: inline;
}
var {
  font-family: $bold;
  border-bottom: dotted 1px black;
}
del, strike {
  text-decoration: line-through;
}
blockquote, q {
  display: block;
  margin: 10px 0 10px 15px;
  padding-left: 15px;
  font-style: italic;
  border-left: solid 2px grey;
}
blockquote p, q p {
  line-height: 160%;
}
cite {
  display: inline-block;
  margin-left: 30px;
  font-family: $bold;
}
pre {
  max-width: 100%;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 15px;
  margin-bottom: 20px;
}
mark, address {
  background-color: $secondary;
  padding: 1px 4px;
}
address {
  margin-bottom: 15px;
  display: inline-block;
  font-size: 80%;
}
ins {
  background: none;
  margin: 0;
  padding: 0;
}
table {
  border-spacing: 0;
  margin-bottom: 30px;
}
table td, table th {
  padding: 8px 12px;
  background-color: $white;
  vertical-align: middle;
  border-spacing: 0;
  border-bottom: solid 1px $gray-400;
}
table th {
  background-color: $gray-400;
  padding: 16px 12px;
  color: white;
  font-family: $bold;
}
dl {
  margin-bottom: 35px;
}
dl dd {
  margin: 0 0 20px 2%;
}
dl dt {
  font-family: $bold;
  margin-bottom: 5px;
}
hr {
  display: block;
  margin: 20px 0;
}
figure {
  margin-bottom: 20px;
}
figure.full img {
  width: 100%;
  margin: 10px auto;
}
figure.half img {
  width: 50%;
}
figure.align-left img {
  float: left;
  margin: 0 15px 5px 0;
}
figure.align-right img {
  float: right;
  margin: 0 0 5px 15px;
}
figure.align-center img {
  margin: 10px auto;
  display: block;
}
figcaption, .footnotes li {
  font-size: 80% !important;
}
.underline {
  text-decoration: underline !important;
}
.black {
  color: $black !important;
}
.primary-color {
  color: $primary !important;
}
.secondary-color {
  color: $secondary;
}
.light-font {
  font-family: $regular !important;
}
.white {
  color: $white !important;
}
.break {
  word-break: break-all;
}
.no-break {
  word-break: keep-all !important;
}
.enlarged-report {
  font-size: 2rem;
  p {
    font-size: 1.7rem;
  }
  ul,ol {
    font-size: 1.7rem;
  }
}

/* special styles call by Shortcodes */
.highlight {
  padding: 10px;
  color: $white;
  background-color: $primary;
}
.highlight.primary {
  background-color: $primary;
  color: $black;
}
.highlight.secondary {
  background-color: $secondary;
  color: $black;
}
.red {
  background-color: $red !important;
}
.yellow {
  background-color: $yellow !important;
  color: $black;
}

/* special style used in templates */
.pageTitle {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
## Short code style
--------------------------------------------------------------*/
.entry p {
  font-size: 20px;
  font-family: $medium;
}
.entry h3 {
  width: 100%;
  clear: both;
  text-align: center;
  font-family: $medium;
  font-size: 20px;
}
