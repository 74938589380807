/*  _components */

div.jumbotron-full {
  height: 100%;
  min-height: 100%;
  border-radius: 0px;
}

button.selectable {
  border-radius: 0px;
}
